import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import HeroBanner from '../../components/Hero'

const hero_settings = {
  size: 'is-medium',
  particle: false,
  images: '',
  css: '#ffafbd,#ffc3a0~#2193b0,#6dd5ed',
  font_color: '#fff',
}

const TermsPage = () => (
  <div>
    <Helmet title={`Terms and Conditions`} />

    <HeroBanner
      title={'Terms and Conditions'}
      subtitle={''}
      hero_settings={hero_settings}
    />

    <section className='section is-feature-grey edge--top'>
      <div className='container content'>
        <p>Last updated: 11th December 2019</p>
        <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the https://layercake.us website and the Layer Cake Solutions App mobile application (the "Service") operated by Layer Cake Solutions ("us", "we", or "our").</p>
        <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
        <p><strong>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</strong></p>
        <br />
        <h3 className='title is-3'>Links To Other Web Sites</h3>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Layer Cake Solutions</p>
        <p>Layer Cake Solutions has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Layer Cake Solutions shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
        <br />
        <h3 className='title is-3'>Changes</h3>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 (change this) days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <br />
        <h3 className='title is-3'>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us.</p>
        <br />
        <br />
      </div>
    </section>
  </div>
)

export default TermsPage
